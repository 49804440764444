import React, { useEffect } from 'react'

import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import Picture from '../../components/Picture'
import CardTecnologia from '../../components/CardTecnologia'
import Features from '../../components/Cumulus23/Features'
import FormNewsletter from '../../components/Newsletter'

import { ditoTrack } from '../../helpers/dito'

import {
  bannerTopoImg,
  bannerTopoMobileImg,
  cumulus22Img,
  cumulus23Img,
  runningGirlImg,
  runningGuyImg,
} from '../../images/cumulus23'

import * as S from './styles'
import { graphql } from 'gatsby'

const Cumulus23Page = () => {
  useEffect(() => {
    ditoTrack('acessou-cumulus-23')
  }, [])

  return (
    <Layout>
      <SEO title="GEL-CUMULUS 23" />

      <Picture
        xs={bannerTopoMobileImg}
        md={bannerTopoImg}
        lg={bannerTopoImg}
        alt="Novo Gel-Cumulus 23"
      />

      <S.WrapperLancamento>
        <S.ItemLancamento>
          <S.ImgLancamento src={runningGuyImg} alt="Running Guy" />
        </S.ItemLancamento>
        <S.ItemLancamento>
          <S.TextWrapperLancamento>
            <h1>
              Conheça o lançamento da ASICS que mistura tecnologia e leveza{' '}
              <strong>GEL-CUMULUS ™ 23</strong>
            </h1>
            <p>
              Um dos grandes clássicos da ASICS surge em nova edição,
              proporcionando uma corrida macia e confortável. O GEL-CUMULUS ™ 23
              tem modelagens diferenciadas por gênero e um design que se adapta
              à anatomia dos pés de quem o usa.
            </p>
          </S.TextWrapperLancamento>
        </S.ItemLancamento>
      </S.WrapperLancamento>

      <Features />

      <S.WrapperTecnologia>
        <S.TitleTecnologia>
          veja as tecnologias <br /> e melhorias
        </S.TitleTecnologia>
        <S.ContentTecnologia>
          <S.ItemTecnologia>
            <img src={cumulus23Img} alt="Gel Cumulus 23" />
            <h2>gel-cumulus ™ 23</h2>
            <CardTecnologia
              title="cabedal"
              tecnologias={[
                'Engineered Mesh para mais respirabilidade para os pés',
                '3D SPACE CONSTRUCTION ™ para mais amortecimento no ciclo da passada e melhorar a experiência de calce',
              ]}
              bgColor="#0485c8"
            />
            <CardTecnologia
              title="entressola"
              tecnologias={[
                'GEL ™ mais maciez, amortecimento e absorção de impacto',
                'FLYTEFOAM ™ mais leve, confortável e alta durabilidade',
              ]}
              bgColor="#fa8452"
            />
          </S.ItemTecnologia>
          <S.ItemTecnologia>
            <img src={cumulus22Img} alt="Gel Cumulus 22" />
            <h2>gel-cumulus ™ 22</h2>
            <CardTecnologia
              title="cabedal"
              tecnologias={[
                'Cabedal respirável com construção em 3D sem costura',
              ]}
              bgColor="#0485c8"
            />
            <CardTecnologia
              title="entressola"
              tecnologias={[
                'GEL™ no calcanhar para dar conforto e segurança na absorção de impactos',
                'FLYTEFOAM™ e FLYTEFOAM ™ PROPEL na entressola para leveza, durabilidade e uma resposta mais rápida e responsiva',
              ]}
              bgColor="#fa8452"
            />
          </S.ItemTecnologia>
        </S.ContentTecnologia>
      </S.WrapperTecnologia>

      <S.WrapperComprar>
        <S.ContentParaQuem>
          <S.TitleComprar>para quem é esse calçado:</S.TitleComprar>
          <S.TextComprar>
            O GEL-CUMULUS™ 23 é um calçado de corrida para corredores com a
            pisada neutra que buscam maior durabilidade durante os treinos
            diários com excelente conforto, amortecimento e muita versatilidade.
          </S.TextComprar>
        </S.ContentParaQuem>
        <S.ContentEspecificacoes>
          <div className="item">
            <S.TitleComprar>especificações:</S.TitleComprar>

            <S.TextComprar>Drop = 10mm(M)</S.TextComprar>
            <S.TextComprar>Drop = 10mm(F)</S.TextComprar>
            <S.TextComprar>Tamanho = 23/13(M)</S.TextComprar>
            <S.TextComprar>Tamanho = 22/12(F)</S.TextComprar>
            <S.TextComprar>Peso = 280g(M)</S.TextComprar>
            <S.TextComprar>Peso = 230g(F)</S.TextComprar>
          </div>
          <div className="item">
            <S.PrecoModelo>R$ 499,99</S.PrecoModelo>
            <S.ButtonComprar
              href="https://www.asics.com.br/calcados?filtro_87=GEL-Cumulus-23&utm_source=lab&utm_medium=lp&utm_campaign=crm-lp-cumulus-23&paged=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              comprar agora
            </S.ButtonComprar>
            <S.ButtonComprar
              href="https://api.whatsapp.com/send/?phone=551150568450&text&app_absent=0"
              target="_blank"
              rel="noopener noreferrer"
            >
              comprar pelo whatsapp
            </S.ButtonComprar>
          </div>
        </S.ContentEspecificacoes>
      </S.WrapperComprar>

      <S.WrapperNewsletter>
        <S.ContentNewsletter>
          <h2>CADASTRE SEU E-MAIL PARA RECEBER OFERTAS E NOVIDADES</h2>

          <S.FormNewsletter>
            <FormNewsletter isLP origem="lp-lab-cumulus23" />
          </S.FormNewsletter>

          <img src={runningGirlImg} alt="Running Girl" />
        </S.ContentNewsletter>
      </S.WrapperNewsletter>
    </Layout>
  )
}

export default Cumulus23Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
