import React, { useState } from 'react'
import Picture from '../../Picture/'
import ButtonFeature from './button'
import FeatureImg from './featureImg'

import * as S from './styles'

import cumulus23Img from '../../../assets/images/cumulus23/cumulus23.png'
import cumulus23MobileImg from '../../../assets/images/cumulus23/cumulus23-mobile.png'

import feature1Img from '../../../assets/images/cumulus23/feature1.png'
import feature2Img from '../../../assets/images/cumulus23/feature2.png'
import feature3Img from '../../../assets/images/cumulus23/feature3.png'
import feature4Img from '../../../assets/images/cumulus23/feature4.png'

import feature1MobileImg from '../../../assets/images/cumulus23/feature1-mobile.png'
import feature2MobileImg from '../../../assets/images/cumulus23/feature2-mobile.png'
import feature3MobileImg from '../../../assets/images/cumulus23/feature3-mobile.png'
import feature4MobileImg from '../../../assets/images/cumulus23/feature4-mobile.png'

const Features = () => {
  const [showFeature, setShowFeature] = useState('')

  return (
    <S.WrapperFeatures>
      <S.wrapperCumulus23>
        <Picture
          xs={cumulus23MobileImg}
          md={cumulus23Img}
          lg={cumulus23Img}
          alt="Novo Gel-Cumulus 23"
        />
        <ButtonFeature
          onClick={() => setShowFeature('feature1')}
          className="btn-feature1"
          type="button"
        >
          feature 1
        </ButtonFeature>
        <ButtonFeature
          onClick={() => setShowFeature('feature2')}
          className="btn-feature2"
          type="button"
        >
          feature 2
        </ButtonFeature>
        <ButtonFeature
          onClick={() => setShowFeature('feature3')}
          className="btn-feature3"
          type="button"
        >
          feature 3
        </ButtonFeature>
        <ButtonFeature
          onClick={() => setShowFeature('feature4')}
          className="btn-feature4"
          type="button"
        >
          feature 4
        </ButtonFeature>

        {showFeature === 'feature1' && (
          <FeatureImg
            className="img-feature1"
            src={feature1Img}
            alt="Nova lingueta e detalhes que agregam valor"
          />
        )}
        {showFeature === 'feature2' && (
          <FeatureImg
            className="img-feature2"
            src={feature2Img}
            alt="Colarinho macio facilitando o calce"
          />
        )}
        {showFeature === 'feature3' && (
          <FeatureImg
            className="img-feature3"
            src={feature3Img}
            alt="Design moderno e palmilha confortável"
          />
        )}
        {showFeature === 'feature4' && (
          <FeatureImg
            className="img-feature4"
            src={feature4Img}
            alt="Nova geometria e mais performance"
          />
        )}

        <S.FeaturesMobile>
          <img
            src={feature1MobileImg}
            alt="Nova lingueta e detalhes que agregam valor"
          />
          <img
            src={feature2MobileImg}
            alt="Colarinho macio facilitando o calce"
          />
          <img
            src={feature3MobileImg}
            alt="Design moderno e palmilha confortável"
          />
          <img
            src={feature4MobileImg}
            alt="Nova geometria e mais performance"
          />
        </S.FeaturesMobile>
      </S.wrapperCumulus23>
    </S.WrapperFeatures>
  )
}

export default Features
